import { Suspense } from "react";
import dynamic from "next/dynamic";
import { NextSeo } from "next-seo";
import Layout from "@/components/layout";
import Top from "@/components/home/top";

const About = dynamic(() => import("@/components/home/about"), {
  suspense: true,
});

const Services = dynamic(() => import("@/components/home/services"), {
  suspense: true,
});

const Testimonial = dynamic(() => import("@/components/home/testimonial"), {
  suspense: true,
});

const Clients = dynamic(() => import("@/components/home/clients"), {
  suspense: true,
});

export default function Index() {
  return (
    <Layout>
      <NextSeo
        title="Documentation, Auditing, Training & Certification: IS Auditr"
        canonical="https://isauditr.com"
        description="ISO Certification and efficient, effective & elegant business solutions. From planning and implementation to reviewing and certifying, leave everything up to us"
        twitter={{
          cardType: "summary_large_image",
          site: "@isauditr",
          handle: "@isauditr",
          creator: "@isauditr",
          title: "Documentation, Auditing, Training & Certification: IS Auditr",
          description:
            "ISO Certification and efficient, effective & elegant business solutions. From planning and implementation to reviewing and certifying, leave everything up to us",
        }}
        openGraph={{
          type: `website`,
          locale: `en_US`,
          siteName: "IS Auditr",
          title: "Documentation, Auditing, Training & Certification: IS Auditr",
          description:
            "ISO Certification and efficient, effective & elegant business solutions. From planning and implementation to reviewing and certifying, leave everything up to us",
          url: `https://isauditr.com`,
          publisherName: "IS Auditr",
          publisherLogo: "https://isauditr.com/images/logo.svg",
        }}
      />

      <div
        data-elementor-type="wp-page"
        data-elementor-id="2"
        className="elementor elementor-2"
      >
        <Top />
        <Suspense fallback={`Loading...`}>
          <About />
          <Services />
          <Testimonial />
          {/* <Clients /> */}
        </Suspense>
      </div>
    </Layout>
  );
}
