import Link from "next/link";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Lazy } from "swiper";

import image1 from "@/images/home-process.webp";
import image2 from "@/images/home-pricing.webp";

export default function Top() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-6ce6702 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="6ce6702"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f6afc5f"
          data-id="f6afc5f"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
            <div
              className="elementor-element elementor-element-158af76 elementor-widget elementor-widget-heading"
              data-id="158af76"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h1 className="elementor-heading-title elementor-size-default">
                  Simplifying Your Search for the Best ISO Auditing Services
                </h1>
              </div>
            </div>

            <div
              className="elementor-element elementor-element-05012b6 elementor-widget elementor-widget-text-editor"
              data-id="05012b6"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                You’re just a click away from a team who is all set to help your
                business grow and succeed.
              </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 text-center lg:pt-0">
              <div className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0">
                <div
                  className="elementor-element elementor-element-34a2ad7 elementor-tablet-align-center elementor-widget elementor-widget-button animated fadeInDown"
                  data-id="34a2ad7"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInDown"}'
                  data-widget_type="button.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-button-wrapper">
                      <Link
                        rel="dofollow"
                        title="Quotation"
                        className="elementor-button-link elementor-button elementor-size-md"
                        href="/quotation"
                      >
                        <span className="elementor-button-content-wrapper">
                          <span className="elementor-button-text">
                            Get your quotation today
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 text-center lg:pt-0">
              <p className="font-display text-base text-primary">
                Trusted by these six companies so far
              </p>

              <ul
                role="list"
                className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
              >
                <li>
                  <ul
                    role="list"
                    className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
                  >
                    <li className="flex">
                      <Image
                        alt="Transistor"
                        src="https://salient.tailwindui.com/_next/static/media/transistor.7274e6c3.svg"
                        width="158"
                        height="48"
                        decoding="async"
                        data-nimg="future"
                        loading="lazy"
                        style="color:transparent"
                      />
                    </li>
                    <li className="flex">
                      <Image
                        alt="Tuple"
                        src="https://salient.tailwindui.com/_next/static/media/tuple.74eb0ae0.svg"
                        width="105"
                        height="48"
                        decoding="async"
                        data-nimg="future"
                        loading="lazy"
                        style="color:transparent"
                      />
                    </li>
                    <li className="flex">
                      <Image
                        alt="StaticKit"
                        src="https://salient.tailwindui.com/_next/static/media/statickit.d7937794.svg"
                        width="127"
                        height="48"
                        decoding="async"
                        data-nimg="future"
                        loading="lazy"
                        style="color:transparent"
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <ul
                    role="list"
                    className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
                  >
                    <li className="flex">
                      <Image
                        alt="Mirage"
                        src="https://salient.tailwindui.com/_next/static/media/mirage.18d2ec4e.svg"
                        width="138"
                        height="48"
                        decoding="async"
                        data-nimg="future"
                        loading="lazy"
                        style="color:transparent"
                      />
                    </li>
                    <li className="flex">
                      <Image
                        alt="Laravel"
                        src="https://salient.tailwindui.com/_next/static/media/laravel.7deed17e.svg"
                        width="136"
                        height="48"
                        decoding="async"
                        data-nimg="future"
                        loading="lazy"
                        style="color:transparent"
                      />
                    </li>
                    <li className="flex">
                      <Image
                        alt="Statamic"
                        src="https://salient.tailwindui.com/_next/static/media/statamic.6da5ebfb.svg"
                        width="147"
                        height="48"
                        decoding="async"
                        data-nimg="future"
                        loading="lazy"
                        style="color:transparent"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div> */}

            {/* <div
              className="elementor-element elementor-element-40cba22 elementor-widget elementor-widget-image-carousel elementor-motion-effects-parent e-widget-swiper"
              data-id="40cba22"
            >
              <div className="elementor-widget-container elementor-motion-effects-element">
                <div
                  dir="ltr"
                  style={{ cursor: "grab" }}
                  className="elementor-image-carousel-wrapper swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal"
                >
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay={{
                      delay: "4000",
                    }}
                    scrollbar={false}
                    pagination={false}
                    modules={[Autoplay, EffectFade, Lazy]}
                  >
                    <SwiperSlide>
                      <figure className="swiper-slide-inner">
                        <Image
                          src={image1}
                          loading="lazy"
                          alt="iso 9001"
                          layout="responsive"
                          className="swiper-slide-image"
                        />
                      </figure>
                    </SwiperSlide>

                    <SwiperSlide>
                      <figure className="swiper-slide-inner">
                        <Image
                          src={image2}
                          loading="lazy"
                          alt="iso 27001"
                          layout="responsive"
                          className="swiper-slide-image"
                        />
                      </figure>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
